<template>
    <div>
      <div class="master-login">
        <div class="table-wrapper">
            <el-table :data="data" stripe style="width: 100%">
                <el-table-column prop="key" label="Key" />
                <el-table-column prop="count" label="Count" />
            </el-table>
        </div>
      </div>
    </div>

  </template>


  
  
  
  <script>
  // import axios from "axios";
  import { fetchhitcount } from "@/api/login";


  export default {
    name: "Login",
    components: {},
    data() {
      return {
        data: {},
      };
    },
       computed: {
      
    },
    mounted() {
      this.getList();
    },
    created() {
     
    },
    methods: {
     
        getList() {
            fetchhitcount().then((response) => {
                this.data =  response.data;
            })
        },
    },
  };
  </script>
  